// @codekit-prepend "../../../node_modules/embla-carousel/embla-carousel.umd.js"
// @codekit-prepend "../../../node_modules/embla-carousel-auto-height/embla-carousel-auto-height.umd.js"
// @codekit-prepend "../../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js"
// @codekit-prepend "../../../node_modules/simple-parallax-js/dist/simpleParallax.min.js"

(function ($) {
	/* Check Window Width */
	// let resizeTimeout = null;
	// let cachedWindowWidth = $(window).width();

	// // Check if window width has actually changed
	// function checkWindowWidth() {
	// 	const newWindowWidth = $(window).width();

	// 	if (newWindowWidth !== cachedWindowWidth) {
	// 		// If changed call functions on resize
	// 		cachedWindowWidth = newWindowWidth;
	// 	}
	// }
	// $(window).on('resize', function() {
	// 	if (resizeTimeout !== null) {
	// 		clearTimeout(resizeTimeout);
	// 	}

	// 	resizeTimeout = setTimeout(checkWindowWidth, 50);
	// });

	/* Format Phone Numbers */
	// Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
	function formatPhoneNumbers() {
		const phoneNumbers = $(".tel");

		phoneNumbers.each((i, el) => {
			let phoneNumber;
			const extension = $(".tel + .ext");

			// Remove '1-' prefix before formatting if it exists
			if ($(el).text().substring(0, 2) === "1-") {
				phoneNumber = $(el).text().replace("1-", "");
			} else {
				phoneNumber = $(el).text();
			}

			// Set phone number, add extension if it exists
			if (extension.length) {
				phoneNumber =
					"tel:1" +
					phoneNumber.replace(/\D/g, "") +
					";ext=" +
					extension.data("ext");
			} else {
				phoneNumber = "tel:1" + phoneNumber.replace(/\D/g, "");
			}

			$(el).attr("href", phoneNumber);
		});
	}
	formatPhoneNumbers();

	/* Sliders */
	function embla() {
		// Previous and next buttons
		const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
			prevBtn.addEventListener("click", embla.scrollPrev, false);
			nextBtn.addEventListener("click", embla.scrollNext, false);
		};

		const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
			return () => {
				if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
				else prevBtn.setAttribute("disabled", "disabled");

				if (embla.canScrollNext()) nextBtn.removeAttribute("disabled");
				else nextBtn.setAttribute("disabled", "disabled");
			};
		};

		// Dot navigation
		const setupDotBtns = (dotsArray, embla) => {
			dotsArray.forEach((dotNode, i) => {
				dotNode.addEventListener("click", () => embla.scrollTo(i), false);
			});
		};

		const generateDotBtns = (dots, embla) => {
			const template = document.getElementById("embla-dot-template").innerHTML;
			dots.innerHTML = embla
				.scrollSnapList()
				.reduce((acc) => acc + template, "");
			return [].slice.call(dots.querySelectorAll(".embla__dot"));
		};

		const selectDotBtn = (dotsArray, embla) => () => {
			const previous = embla.previousScrollSnap();
			const selected = embla.selectedScrollSnap();
			dotsArray[previous].classList.remove("is-selected");
			dotsArray[selected].classList.add("is-selected");
		};

		// Gallery slider
		function gallerySlider() {
			const wrap = document.querySelector(".gallery-slider.embla");
			const viewPort = wrap.querySelector(".gallery-slider .embla__viewport");
			const prevBtn = wrap.querySelector(
				".gallery-slider .embla__button--prev"
			);
			const nextBtn = wrap.querySelector(
				".gallery-slider .embla__button--next"
			);
			const dots = wrap.querySelector(".gallery-slider .embla__dots");
			const embla = EmblaCarousel(
				viewPort,
				{
					align: "start",
					breakpoints: {
						"(min-width: 768px)": { dragFree: false },
						// "(min-width: 1201px)": { align: "center" },
					},
					dragFree: true,
					loop: true,
					// skipSnaps: false,
					// startIndex: -1,
				}
				// [
				// 	EmblaCarouselAutoHeight({
				// 		destroyHeight: "auto",
				// 	}),
				// ]
			);

			const dotsArray = generateDotBtns(dots, embla);
			const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			const disablePrevAndNextBtns = disablePrevNextBtns(
				prevBtn,
				nextBtn,
				embla
			);

			setupPrevNextBtns(prevBtn, nextBtn, embla);
			setupDotBtns(dotsArray, embla);

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			function slidesNotInViewStyles() {
				const slideNodes = embla.slideNodes();
				const slidesNotInView = embla.slidesNotInView();

				for (let i = 0; i < slideNodes.length; i++) {
					if (slidesNotInView.includes(i)) {
						slideNodes[i].classList.add("not-in-view");
					} else {
						slideNodes[i].classList.remove("not-in-view");
					}
				}
			}
			slidesNotInViewStyles();

			embla.on("select", setSelectedDotBtn);
			embla.on("select", disablePrevAndNextBtns);
			embla.on("init", setSelectedDotBtn);
			embla.on("init", disablePrevAndNextBtns);
			embla.on("scroll", slidesNotInViewStyles);
		}
		const gallerySliderEl = document.querySelector(".gallery-slider");
		if (gallerySliderEl) {
			gallerySlider();
		}

		// Testimonial slider
		function testimonialSlider() {
			const wrap = document.querySelector(".testimonial-slider.embla");
			const viewPort = wrap.querySelector(
				".testimonial-slider .embla__viewport"
			);
			const prevBtn = wrap.querySelector(
				".testimonial-slider .embla__button--prev"
			);
			const nextBtn = wrap.querySelector(
				".testimonial-slider .embla__button--next"
			);
			const dots = wrap.querySelector(".testimonial-slider .embla__dots");
			const embla = EmblaCarousel(viewPort, {
				align: "start",
				breakpoints: {
					"(min-width: 768px)": { dragFree: false },
					"(min-width: 1201px)": { align: "center" },
				},
				dragFree: true,
				loop: true,
				// skipSnaps: false,
				// startIndex: -1,
			});

			const dotsArray = generateDotBtns(dots, embla);
			const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			const disablePrevAndNextBtns = disablePrevNextBtns(
				prevBtn,
				nextBtn,
				embla
			);

			setupPrevNextBtns(prevBtn, nextBtn, embla);
			setupDotBtns(dotsArray, embla);

			// Make all menu item titles the same height
			function sameHeightTestimonials() {
				setTimeout(function () {
					const testimonialEls = $(
						".testimonial-slider.embla .review .excerpt p"
					);
					let largestHeight = 0;

					testimonialEls.each(function () {
						if ($(this).height() > largestHeight) {
							largestHeight = $(this).height();
						}
					});

					testimonialEls.height(largestHeight);
				}, 100);
			}
			sameHeightTestimonials();

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			function slidesNotInViewStyles() {
				const slideNodes = embla.slideNodes();
				const slidesNotInView = embla.slidesNotInView();

				for (let i = 0; i < slideNodes.length; i++) {
					if (slidesNotInView.includes(i)) {
						slideNodes[i].classList.add("not-in-view");
					} else {
						slideNodes[i].classList.remove("not-in-view");
					}
				}
			}
			slidesNotInViewStyles();

			embla.on("select", setSelectedDotBtn);
			embla.on("select", disablePrevAndNextBtns);
			embla.on("init", setSelectedDotBtn);
			embla.on("init", disablePrevAndNextBtns);
			embla.on("reInit", sameHeightTestimonials);
			embla.on("scroll", slidesNotInViewStyles);
		}
		const testimonialSliderEl = document.querySelector(".testimonial-slider");
		if (testimonialSliderEl) {
			testimonialSlider();
		}

		// Menu section navigation
		function menuSectionNavigation() {
			const wrap = document.querySelector(
				".menu-section-navigation-inner-wrapper.embla"
			);
			const viewPort = wrap.querySelector(
				".menu-section-navigation-inner-wrapper .embla__viewport"
			);
			const embla = EmblaCarousel(viewPort, {
				active: true,
				align: "start",
				// breakpoints: {
				// 	"(min-width: 992px)": { active: false },
				// },
				containScroll: "trimSnaps",
				dragFree: true,
				// loop: true,
				// skipSnaps: false
			});
		}
		const menuSectionNavigationEl = document.querySelector(
			".menu-section-navigation-inner-wrapper"
		);
		if (menuSectionNavigationEl) {
			menuSectionNavigation();
		}
	}
	const emblaEl = document.querySelector(".embla");
	if (emblaEl) {
		embla();
	}

	/* Popups */
	// Image
	$(".open-image-link").magnificPopup({
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		type: "image",
	});

	// Video
	$(".open-video-link").magnificPopup({
		// closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		preloader: false,
		removalDelay: 100,
		type: "iframe",
	});

	// Order Now
	$(".order-now-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				const popup = $(".order-now-popup");

				if (popup.length) {
					popup.parent().parent().addClass("mfp-order-now");
				}
			},
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		items: {
			src: ".order-now-popup",
		},
		mainClass: "mfp-fade",
		removalDelay: 200,
		type: "inline",
	});

	// Testimonial popup (ajax)
	setTimeout(function () {
		$(".open-testimonial-link").magnificPopup({
			callbacks: {
				open: function () {
					setTimeout(function () {
						// Add class to mfp-container element for css
						var testimonialPopup = $(".testimonial-popup");

						if (testimonialPopup.length) {
							testimonialPopup.parent().parent().addClass("mfp-testimonial");
						}
					}, 1000);
				},
				parseAjax: function (mfpResponse) {
					mfpResponse.data = $(mfpResponse.data).find("#testimonial");
				},
			},
			closeBtnInside: true,
			closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
			mainClass: "mfp-fade",
			preloader: false,
			removalDelay: 200,
			type: "ajax",
		});
	}, 100);

	// Testimonial popup (inline)
	setTimeout(function () {
		$(".open-testimonial-link-inline").on("click", function () {
			var testimonialPopupContent = $(this).closest(".testimonial").html();

			$.magnificPopup.open({
				callbacks: {
					// beforeOpen: function() {
					//   console.log(this.st.el.closest('.pestpac-review'));
					// },
					open: function () {
						setTimeout(function () {
							// Add class to mfp-container element for css
							var testimonialPopup = $(".testimonial-popup");

							if (testimonialPopup.length) {
								testimonialPopup.parent().parent().addClass("mfp-testimonial");
							}
						}, 1000);
					},
				},
				closeBtnInside: true,
				closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
				items: {
					src:
						'<div class="testimonial-popup popup row"><div class="testimonial col-xs-12">' +
						testimonialPopupContent +
						"</div></div>",
					type: "inline",
				},
			});
		});
	}, 100);

	/* Miscellanous */
	/** Testimonials */
	// Remove empty p tags
	// $('.testimonial p:empty').remove();

	// List or single
	if ($(".testimonials-list").length || $(".single-testimonial").length) {
		// Remove excerpt in testimonial list
		$(".testimonial.no-excerpt .review .excerpt").remove();
	}

	// Slider
	if ($(".testimonial-slider").length) {
		$(".testimonial").each(function () {
			const testimonialContent = $(this).find(".content");
			const testimonialExcerpt = $(this).find(".excerpt > p");
			const testimonialExcerptReadMore = $(this).find(".excerpt > p.read-more");
			const testimonialPermalink = $(this).data("permalink");

			// If excerpt exists remove content div
			if (testimonialExcerpt.length) {
				// Remove content
				testimonialContent.remove();

				// Append Permalink
				if (testimonialExcerptReadMore.length) {
					testimonialExcerpt.append(
						' <a href="' +
							testimonialPermalink +
							'" class="open-testimonial-link" title="">Read More</a>'
					);
				}
			}
		});
	}

	/* Form File Downloads */
	// Handle automatic file download on thank you page
	function getUrlParameter(sParam) {
		const sPageURL = window.location.search.substring(1);
		const sURLVariables = sPageURL.split("&");
		let sParameterName = null;

		for (let i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split("=");

			if (sParameterName[0] === sParam) {
				return typeof sParameterName[1] === undefined
					? true
					: decodeURIComponent(sParameterName[1]);
			}
		}
		return false;
	}

	/**
	 * Simulate a click event
	 * @public
	 * @param {Element} elem  the element to simulate a click on
	 */
	const simulateClick = function (elem) {
		// Create our event (with options)
		const evt = new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		});

		// If cancelled, don't dispatch our event
		const canceled = !elem.dispatchEvent(evt);
	};

	const startDownloadLink = document.querySelector(".start-download-link a");

	function handleStartDownloadLink(startDownloadLink) {
		// Get file URL value from current URL
		const fileURL = getUrlParameter("file_url");

		// Set link href to this file URL
		startDownloadLink.setAttribute("href", fileURL);

		// Click link to initiate open/download dialog only if href value exists
		if (startDownloadLink.getAttribute("href") != "false") {
			simulateClick(startDownloadLink);
		}
	}
	if (startDownloadLink) {
		handleStartDownloadLink(startDownloadLink);
	}

	// Job Location Filter ajax
	function jobLocationFilter() {
		$(".location-filter > select").on("change", function () {
			// If All Locations selected, redirect to main jobs page; this prevents issues with pagination
			if ($(this).val() === "") {
				window.location =
					window.location.protocol + "//" + window.location.host + "/careers/";
			} else {
				$.ajax({
					type: "post",
					url: "/wp-admin/admin-ajax.php",
					data: {
						action: "job_by_location_filter",
						job_by_location_filter: $(this).val(),
					},
					success: function (data) {
						// console.log(data);
						$(".jobs-wrapper").html(data);
					},
				});
				return false;
			}
		});
	}
	if ($(".jobs.acf-block").length) {
		jobLocationFilter();
	}

	// Menu Sections
	function menuScripts() {
		// Menu Section navigation
		// Add 'current' class to appropriate navigation item
		function currentNavigationItem() {
			const activeMenuSectionId = $(".menu.acf-block").attr(
				"data-active-menu-section"
			);
			const menuSectionNavigationEls = $(".menu-section-navigation .menu-link");

			menuSectionNavigationEls.each(function () {
				if ($(this).attr("data-menu-section-id") === activeMenuSectionId) {
					$(this).addClass("current");
				} else {
					$(this).removeClass("current");
				}
			});
		}
		currentNavigationItem();

		// Reinitialize order now popup
		function orderNowPopup() {
			$(".order-now-link").magnificPopup({
				callbacks: {
					open: function () {
						// Add class to mfp-container element for css
						const popup = $(".order-now-popup");

						if (popup.length) {
							popup.parent().parent().addClass("mfp-order-now");
						}
					},
				},
				closeBtnInside: true,
				closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
				items: {
					src: ".order-now-popup",
				},
				mainClass: "mfp-fade",
				removalDelay: 200,
				type: "inline",
			});
		}

		// Menu backgrounds
		function menuBg() {
			// Foreground
			const foreground = document.querySelectorAll(
				".menu-background .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: 0.1,
				scale: 1.2,
				overflow: true,
				transition: "cubic-bezier(0,0,0,1)",
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".menu-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: 0.15,
				scale: 1.25,
				orientation: "down",
				overflow: true,
				transition: "cubic-bezier(0,0,0,1)",
			});

			// Background
			const background = document.querySelectorAll(
				".menu-background .background > div"
			);
			new simpleParallax(background, {
				delay: 0.2,
				scale: 1.35,
				orientation: "down",
				overflow: true,
				transition: "cubic-bezier(0,0,0,1)",
			});
		}

		// Menu Section Navigation Ajax
		function menuSectionNavigation(clickedMenuItem, menuSectionId) {
			// If getting here by URL we need to assign the correct clickedMenuItem selector
			if (clickedMenuItem === null) {
				clickedMenuItem = $(
					'.menu-section-navigation .menu-link[data-menu-section-id="' +
						menuSectionId +
						'"]'
				);
			}

			$.ajax({
				type: "post",
				url: "/wp-admin/admin-ajax.php",
				data: {
					action: "menu_section_navigation",
					menu_section_navigation: menuSectionId,
				},
				success: function (data) {
					// console.log(data);
					$(".menu-section-wrapper").html(data);
					$(".menu.acf-block").attr(
						"data-active-menu-section",
						clickedMenuItem.data("menu-section-id")
					);
					currentNavigationItem();
					orderNowPopup();
					menuBg();
				},
			});
			return false;
		}
		// On menu section navigation click, call function, pass along menu section id
		$(".menu-section-navigation .menu-link").on("click", function () {
			menuSectionNavigation($(this), $(this).data("menu-section-id"));
		});

		// Check if menu section parameter is in URL, call function if so, pass along menu section id
		const menuSectionId = getUrlParameter("menu_section_id");
		if (menuSectionId.length) {
			menuSectionNavigation(null, menuSectionId);
		}
	}
	if ($(".menu.acf-block").length) {
		menuScripts();
	}

	// Popular Menu Sections
	function popularMenuScripts() {
		// Menu Section navigation
		// Add 'current' class to appropriate navigation item
		function currentNavigationItem() {
			const activeMenuCategoryId = $(".popular-menu.acf-block").attr(
				"data-active-menu-category"
			);
			const menuCategoryNavigationEls = $(
				".menu-category-navigation .menu-link"
			);

			menuCategoryNavigationEls.each(function () {
				if ($(this).attr("data-menu-category-id") === activeMenuCategoryId) {
					$(this).addClass("current");
				} else {
					$(this).removeClass("current");
				}
			});
		}
		currentNavigationItem();

		// Make all menu item titles the same height
		function sameHeightTitles() {
			const titleEls = $(".popular-menu.acf-block .menu-item .title");
			let largestHeight = 0;

			titleEls.each(function () {
				if ($(this).height() > largestHeight) {
					largestHeight = $(this).height();
				}
			});

			titleEls.height(largestHeight);
		}
		setTimeout(sameHeightTitles, 500);

		// Reinitialize order now popup
		function orderNowPopup() {
			$(".order-now-link").magnificPopup({
				callbacks: {
					open: function () {
						// Add class to mfp-container element for css
						const popup = $(".order-now-popup");

						if (popup.length) {
							popup.parent().parent().addClass("mfp-order-now");
						}
					},
				},
				closeBtnInside: true,
				closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
				items: {
					src: ".order-now-popup",
				},
				mainClass: "mfp-fade",
				removalDelay: 200,
				type: "inline",
			});
		}

		// Category navigation
		function menuCategoryNavigation() {
			const wrap = document.querySelector(
				".popular-menu.acf-block .menu-category-navigation-inner-wrapper.embla"
			);
			const viewport = wrap.querySelector(
				".popular-menu.acf-block .menu-category-navigation-inner-wrapper .embla__viewport"
			);
			const embla = EmblaCarousel(viewport, {
				active: true,
				align: "center",
				// breakpoints: {
				// 	"(min-width: 768px)": { active: false },
				// },
				containScroll: "trimSnaps",
				dragFree: true,
				// loop: true,
				// skipSnaps: false
			});
		}
		menuCategoryNavigation();

		// Menu items slider
		function menuItemsSlider() {
			const wrap = document.querySelector(
				".popular-menu.acf-block .menu-items-inner-wrapper.embla"
			);
			const viewport = wrap.querySelector(
				".popular-menu.acf-block .menu-items-inner-wrapper .embla__viewport"
			);
			const embla = EmblaCarousel(viewport, {
				active: true,
				align: "start",
				breakpoints: {
					// "(min-width: 992px)": { active: false },
				},
				// containScroll: "trimSnaps",
				dragFree: true,
				// loop: true,
				// skipSnaps: false
			});

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			function slidesNotInViewStyles() {
				const slideNodes = embla.slideNodes();
				const slidesNotInView = embla.slidesNotInView();

				for (let i = 0; i < slideNodes.length; i++) {
					if (slidesNotInView.includes(i)) {
						slideNodes[i].classList.add("not-in-view");
					} else {
						slideNodes[i].classList.remove("not-in-view");
					}
				}
			}
			// slidesNotInViewStyles();

			// embla.on("scroll", slidesNotInViewStyles);
		}
		menuItemsSlider();

		// Menu Section Navigation Ajax
		$(".menu-category-navigation .menu-link").on("click", function () {
			const clickedMenuItem = $(this);

			$.ajax({
				type: "post",
				url: "/wp-admin/admin-ajax.php",
				data: {
					action: "popular_menu_navigation",
					popular_menu_category_id: $(this).data("menu-category-id"),
					menu_item_ids: $(".popular-menu.acf-block").data(
						"popular-menu-item-ids"
					),
				},
				success: function (data) {
					// console.log(data);
					$(".menu-items-outer-wrapper").html(data);
					$(".popular-menu.acf-block").attr(
						"data-active-menu-category",
						clickedMenuItem.data("menu-category-id")
					);
					menuItemsSlider();
					currentNavigationItem();
					setTimeout(sameHeightTitles, 500);
					orderNowPopup();
				},
			});
			return false;
		});
	}
	if ($(".popular-menu.acf-block").length) {
		popularMenuScripts();
	}

	// Background Parallax
	function parallaxBackgrounds() {
		// Global delay and scale settings
		const globalOptions = {
			pFD: 0.1, // Foreground delay
			pFS: 1.2, // Foreground scale
			mFD: 0.15, // Middleground delay
			mFS: 1.25, // Middleground scale
			bFD: 0.2, // Background delay
			bFS: 1.35, // Background scale
			transition: "cubic-bezier(0,0,0,1)",
		};

		function siteBgTopMain(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".site-top-background .main .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".site-top-background .main .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".site-top-background .main .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".site-top-background .main").length) {
			siteBgTopMain(globalOptions);
		}

		function siteBgTopSecondary(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".site-top-background .secondary .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".site-top-background .secondary .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".site-top-background .secondary .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".site-top-background .secondary").length) {
			siteBgTopSecondary(globalOptions);
		}

		function siteBgBottom(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".site-bottom-background .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".site-bottom-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".site-bottom-background .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".site-bottom-background").length) {
			siteBgBottom(globalOptions);
		}

		function downloadAppBg(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".download-app-background .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".download-app-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".download-app-background .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".download-app-background").length) {
			downloadAppBg(globalOptions);
		}

		function menuBg(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".menu-background .foreground > div"
			);
			console.log(foreground);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".menu-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".menu-background .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".menu-background").length) {
			menuBg(globalOptions);
		}

		function testimonialsBg(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".testimonials-background .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".testimonials-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".testimonials-background .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".testimonials-background").length) {
			testimonialsBg(globalOptions);
		}

		function formBg(globalOptions) {
			// Foreground
			const foreground = document.querySelectorAll(
				".form-background .foreground > div"
			);
			new simpleParallax(foreground, {
				delay: globalOptions.pFD,
				scale: globalOptions.pFS,
				overflow: true,
				transition: globalOptions.transition,
			});

			// Middleground
			const middleground = document.querySelectorAll(
				".form-background .middleground > div"
			);
			new simpleParallax(middleground, {
				delay: globalOptions.mFD,
				scale: globalOptions.mFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});

			// Background
			const background = document.querySelectorAll(
				".form-background .background > div"
			);
			new simpleParallax(background, {
				delay: globalOptions.bFD,
				scale: globalOptions.bFS,
				orientation: "down",
				overflow: true,
				transition: globalOptions.transition,
			});
		}
		if ($(".form-background").length) {
			formBg(globalOptions);
		}
	}
	parallaxBackgrounds();

	function mobileOrderNowButtonFix() {
		const mobileOrderNowButton = $(
			".main-header-menu .menu-item.order-now-link"
		);
		const mobilePopup = $(".ast-mobile-popup-drawer");
		const mobileMenuButton = $(".main-header-menu-toggle");

		mobileOrderNowButton.on("click", function () {
			mobilePopup.removeClass("active show");
			mobileMenuButton.css("display", "flex");
		});
	}
	mobileOrderNowButtonFix();
})(jQuery);
